import React from 'react';
import styled, { keyframes } from 'styled-components';
import urlFor from '../helper/urlFor';

const HeroStyle = styled.div`
    text-align: center;
    ${'' /* background-image: url(${props => urlFor(props.bg[0].forEach(item => item))}); */}
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-size: 3rem;
    color: #ffce7e;
    width: 100%;
    height: 100%;
    animation: slideIn 12s infinite both;
    @media (max-width: 768px) {
        font-size: 2rem;
        height: auto;
        background-position: 20%;
    }
    div {
      padding: 100px 22% 50px;
      height: 700px;
      background-color: rgba(47, 47, 47, 0.41);
      line-height: 1.5;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        height: auto;
        padding: 50px;
      }
    }

    @keyframes slideIn {
        0% {
            background-image: url(${props => urlFor(props.bg[0][0])});
        }
        50% {
            background-image: url(${props => urlFor(props.bg[0][1])});
        }
        100% {
            background-image: url(${props => urlFor(props.bg[0][2])});
        }
    }
`;

export default function SlideShow({title, bg, backupBg}) {
    console.log(title)
    return (
        <HeroStyle bg={bg}>
            <div>
                <h2>{title}</h2>
            </div>
        </HeroStyle>
    )
}