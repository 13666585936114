import React from "react"
import { graphql, Link } from "gatsby"
import PortableText from '@sanity/block-content-to-react'
import Img from 'gatsby-image';
import Layout from "../components/layout"
// import Hero from "../components/hero"
import SlideShow from "../components/slideshow";
import SEO from "../components/seo"
import GetInvolved from "../components/getinvolved";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import urlFor from "../helper/urlFor";

const SectionStyle = styled.div`
  .section {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .gatsby-image-wrapper {
      flex: 1;
    }
    .preview {
      flex: 2;
      align-self: center;
      @media (max-width: 1024px) {
        flex: 1;
      }
      h3 {
        font-size: 2.7rem;
        color: #14213D;
      }
      p {
        font-size: 1.15rem;
        line-height: 1.5;
        padding-bottom: 1rem;
      }

      a {
        text-decoration: none;
        margin-top: 1rem;
      }
    }
    :nth-child(1) {
      background-color: #7DCBEF;
      .gatsby-image-wrapper {
        flex: 1 10%;
        @media (max-width: 812px) {
        flex: 1 100%;
        order: 2;
        }
      }
      .container {
        padding: 50px 40px;
      }
    }
    :nth-child(2) {
      flex-direction: row-reverse;
      .gatsby-image-wrapper {
        flex: 1 15%;
        @media (max-width: 1024px) {
          flex: 1 10%;
        }
        @media (max-width: 812px) {
          flex: 1 100%;
          order: 2;
        }
      }
      .container {
        padding: 50px 40px;
      }
    }
  }
`;

const IndexPage = ({data}) => {
  const { t, i18n } = useTranslation("home");
  const enText = data.page._rawContent.filter(content => content.language === "en");
  const phText = data.page._rawContent.filter(content => content.language === "ph");
  const hero = data.page._rawContent.filter(content => content._type === "hero");
  const slideshow = data.page._rawContent.filter(content => content._type === "slideshow");
  const slides = slideshow.map(slide => slide.bgImages.map(image => image.bgImage.asset));

  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <SlideShow 
        title={i18n.language === "en" ? hero[0].heading.en : hero[0].heading.ph} 
        backupBg={urlFor(hero[0].bgImage.asset)}
        bg={slides}

        />
          <SectionStyle>
            {data.allFile.edges.map((edge, index) => (
              <div className="section" key={edge.node.id}>
                <Img fluid={edge.node.childImageSharp.fluid} />
                <div className="preview">
                  <div className="container">
                    <h3>
                      { edge.node.name === "home-01" ? 
                        (i18n.language === "en"? enText[0].title : phText[0].title) :
                        (i18n.language === "en" ? enText[1].title : phText[1].title) 
                      }
                    </h3>
                    { edge.node.name === "home-01" ? 
                      (i18n.language === "en" ?
                        <PortableText
                          blocks={enText[0].body}
                        /> :
                        <PortableText
                          blocks={phText[0].body}
                        /> 
                      ) : 
                      (i18n.language === "en" ? 
                        <PortableText
                          blocks={enText[1].body}
                        /> :
                        <PortableText
                          blocks={phText[1].body}
                        />
                        )
                    }
                  { edge.node.name === "home-01" ? 
                      (i18n.language === "en" ? 
                        <Link to={"/" + i18n.language + "/about"} className="button">More About Us</Link> :
                        <Link to={"/" + i18n.language + "/about"} className="button">Higit pa tungkol sa amin</Link> 
                      )
                    : null
                    }
                  </div>
                </div>
              </div>
            ))}
          </SectionStyle>
      </div>
      <GetInvolved />
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    page: sanityPages(name: {eq: "Home"}) {
      _rawContent
      content {
        ... on SanitySlideshow {
          _key
          _type
          bgImages {
            _key
            _type
            label
            bgImage {
              _key
              _type
            }
          }
        }
        ... on SanityHero {
          _key
          _type
          bgImage {
            _key
            _type
          }
          label
        }
        ... on SanitySectionText {
          _key
          _type
          language
          title
          body {
            _key
            _type
            style
            list
          }
        }

      }
    }
    allFile(filter: {relativeDirectory: {eq: "home"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
